import React, {useEffect, useState, useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {MDBRow, MDBCol} from "mdbreact";
import moment from "moment";
import * as R from 'ramda';
import CheckboxIntl from "../../components/CheckboxIntl";

import {InputRestrictors as restrict} from "../../utils/validation/standard-validations";
import DateField from "../../components/DateField";
import SelectFieldIntl from "../../components/SelectFieldIntl";
import InputFieldIntl from "../../components/InputFieldIntl";
import CCDetails from "../../components/UPS/PaymentMethodDetails/CCDetails";
import DCDetails from "../../components/UPS/PaymentMethodDetails/DCDetails";
import DDDetails from "../../components/UPS/PaymentMethodDetails/DDDetails";
import PayPalAccountDetails from "../../components/UPS/PaymentMethodDetails/PayPalAccountDetails";
import SepaDetails from "../../components/UPS/PaymentMethodDetails/SepaDetails";
import BacsDetails from "../../components/UPS/PaymentMethodDetails/BacsDetails/BacsDetails";
import SCBDetails from "../../components/UPS/PaymentMethodDetails/SCBDetails";
import AlternatePmWithIssuersDetails from "../../components/UPS/PaymentMethodDetails/AlternatePmWithIssuersDetails";
import {
    pmCatRequiresAddress,
    isPaymentComplete,
    isRealTimeOnlyCard,
    isDeferPayWithWalletOnly,
    getMerchantPaymentCategories,
    getNonCreditPaymentCategories,
    pmTypeValidMinPaymentAmount,
    pmTypeValidMaxPaymentAmount,
    displayWalletSelection, formatPMCategoryDesc
} from '../../utils/payment-utils';
import {generateCountryList, sortCountryList} from "../../utils/utils";
import {getPayLaterRange} from "../../utils/config-utils";
import {countryData} from "../../constants/country-data";

import Details from "./Details";
import AlternatePmNoInput from "../../components/UPS/PaymentMethodDetails/AlternatePmNoInput";
import Alert from "../../components/Alert";
import AchAgreementConfirmation from "../../components/UPS/AchAgreementConfirmation/index"
import BlikDetails from "../../components/UPS/PaymentMethodDetails/BlikDetails";
import {isAgreementOptionSelected} from "../../utils/payment-method-utils";
import {useValidator} from "../../utils/validation";

const PAY_NOW = "payNow", PAY_LATER = "payLater";
const USE_WALLET = "wallet", USE_NEW = "new", USE_ALTER = "alter";

const hasNickName = R.allPass(
    [ R.has('cardNickName') 
    , R.propSatisfies(R.complement(R.isNil), 'cardNickName')
    , R.propSatisfies(R.complement(R.isEmpty), 'cardNickName')
    ]
);

const getDisplayPmCategoryOptions = (intl, creditAllowed = true) => R.compose
    ( R.map(value => ({value, label: formatPMCategoryDesc (intl) (value)}))
    , R.when(R.always(!creditAllowed), getNonCreditPaymentCategories)
    , getMerchantPaymentCategories ()
    );

const getDisplayAltCategoryOptions = (intl, creditAllowed = true) => R.compose
    ( R.map(value => ({value, label: formatPMCategoryDesc (intl) (value)}))
    , R.when(R.always(!creditAllowed), getNonCreditPaymentCategories)
    , getMerchantPaymentCategories (true)
    );

const getPayOptions = ({formatMessage: f}) => R.map(
    R.cond([
        [R.equals(USE_WALLET), value => ({value, label: f({id: "payment-method.description.wallet"})})],
        [R.equals(USE_NEW), value => ({value, label: f({id: "payment-method.description.new"})})],
        [R.equals(USE_ALTER), value => ({value, label: f({id: "payment-method.description.alter"})})]
    ])
);

const getPMMethodTypefromId = (pmId, wallet) => {
    if (!pmId) return undefined
    return wallet.find(pm => pm.id === pmId)?.methodType ?? pmId
}

const isSavePMType = (paymentMethod, merchant) => {
    if (!paymentMethod) return false;

    const { methodType } = paymentMethod;
    if (methodType === null || methodType === "" ) return false;
    const saveToPmTypes = getSaveToWalletPmTypesForMerchant(merchant);
    return saveToPmTypes.filter(pm => pm === methodType).length > 0;
}

const getSaveToWalletPmTypesForMerchant = R.compose(
    R.split(','),
    R.when(
        R.isNil,
        _ => ''
    ),
    R.view(R.lensPath(['clientConfiguration', 'BC_PMT_SAVE_TO_WALLET_PM_TYPES'])),
);

const getPayOptionsArray = (wallet, altCatOptions) => {
    let payOptionsArray = [USE_NEW]
    if(wallet?.length > 0) payOptionsArray.unshift(USE_WALLET)
    if(altCatOptions?.length > 0) payOptionsArray.push(USE_ALTER)
    return payOptionsArray
}

const getDefaultPayOption = (payOptionsArray, pmCatOptions, altCatOptions, pmid) => {
    const optionIsCurrentPM = pm => pm.value === pmid;
    if(pmCatOptions.find(optionIsCurrentPM)) return USE_NEW // if we are coming back to this page from a subsequent step with a new payment method, default to new
    if(altCatOptions.find(optionIsCurrentPM)) return USE_ALTER // if we are coming back to this page from a subsequent step with an alternate payment method, default to alternate
    if(payOptionsArray.includes(USE_WALLET)) return USE_WALLET // otherwise, if we have wallet entries, default to wallet entry option
    else return USE_NEW // otherwise, default to new
}

// Step 2
export default function PaymentInformation({
    wallet, paymentMethod, onPMChange,
    date, onDateChange, onNext, onBack, creditAllowed,
    onSavePM, isSavePayMethod, onError, isRefund, hasCredits,
    daysTilDueDate, onAuthorizeACH, authorizeDD, totalAmount
}) {
    const intl = useIntl();
    const validator = useValidator();

    const { user: { selectedCountry: defaultUserCountry }} = useSelector(s => s.auth);
    const { sessionSelection, countries } = useSelector(s => s.config);
    const { merchant, businessUnit } = sessionSelection;
    const countryCd = paymentMethod?.address?.country ?? defaultUserCountry
    //const countries = getAllCountryCodes();
    const payLaterRange = useMemo(() => getPayLaterRange (daysTilDueDate) (merchant), [daysTilDueDate, merchant])

    const noneOption = {
        value: "",
        label: intl.formatMessage({id: "payment-method.placeholder"})
    };

    // Build from wallet
    const walletOptions = [noneOption].concat(wallet.map(pm => ({
        value: pm.id,
        label: displayWalletSelection (intl) (pm)
    })));

    // Build from country PM categories
    const pmCatOptions = getDisplayPmCategoryOptions (intl, creditAllowed) (merchant);
    const newOptions = [noneOption].concat(pmCatOptions);

    // Build from country alternate PM categories
    const altCatOptions = getDisplayAltCategoryOptions (intl, creditAllowed) (merchant);
    const altOptions = [noneOption].concat(altCatOptions);

    // Build payment options
    const payOptionsArray = getPayOptionsArray(wallet, altCatOptions)
    const payOptions = getPayOptions(intl)(payOptionsArray)

    // Local state
    const [canProceed, setCanProceed] = useState(false);
    const [payUsing, setPayUsing] = useState(getDefaultPayOption(payOptionsArray, pmCatOptions, altCatOptions, paymentMethod?.id));
    const [isDisplaySavePmOption, setSavePMOption ] = useState(false);
    const [focused, setFocused] = useState(false);
    const [payLater, setPayLater] = useState(!!date);

    // Form fragments
    const formProps = {
        controlled: true,
        hideNickField: true,
        paymentMethod,
        country: countryCd,
        extraFields: true,
        submitAction: onPMChange,
        setCanProceed,
        isACHAuthRequired: true
    };
    const pmForms = {
        CC: <CCDetails {...formProps} pmCategory="CC"/>,
        DC: <DCDetails {...formProps} pmCategory="DC"/>,
        DD: <DDDetails {...formProps} pmCategory="DD"/>,
        PAYPAL_ACCOUNT: <PayPalAccountDetails {...formProps} pmCategory="PAYPAL_ACCOUNT"/>,
        SEPA: <SepaDetails {...formProps} pmCategory="SEPA"/>,
        BACS: <BacsDetails {...formProps} pmCategory="BACS"/>,
        DOTPAY: <AlternatePmWithIssuersDetails {...formProps} pmCategory="DOTPAY" merchantId={merchant.id} onError={onError}/>,
        ONLINEBANKING_PL: <AlternatePmWithIssuersDetails {...formProps} pmCategory="ONLINEBANKING_PL" merchantId={merchant.id} onError={onError}/>,
        IDEAL: <AlternatePmNoInput {...formProps} pmCategory="IDEAL"/>,
        WECHAT_PAY: <AlternatePmNoInput {...formProps} pmCategory="WECHAT_PAY" />,
        ALIPAY: <AlternatePmNoInput {...formProps} pmCategory="ALIPAY" />,
        TRUSTLY: <AlternatePmNoInput  {...formProps} pmCategory="TRUSTLY"/>,
        MOMO_WALLET: <AlternatePmNoInput  {...formProps} pmCategory="MOMO_WALLET"/>,
        VIPPS: <AlternatePmNoInput  {...formProps} pmCategory="VIPPS"/>,
        BLIK: <BlikDetails  {...formProps} pmCategory="BLIK"/>,
        SWISH: <AlternatePmNoInput  {...formProps} pmCategory="SWISH"/>,
        TWINT: <AlternatePmNoInput  {...formProps} pmCategory="TWINT"/>,
        SCB: <SCBDetails {...formProps} pmCategory="SCB"/>
    };

    useEffect(() => {
        setCanProceed(isPaymentComplete(paymentMethod));
        setSavePMOption(payUsing === USE_NEW && isSavePMType(paymentMethod, merchant))
    }, [paymentMethod]);

    useEffect(()=>{
        //automatically re-validate zip code when country changes if zip code is rendered and has a value
        if(!!validator.getVField('zipCode')?.getValue()) validator.validateGetValue('zipCode')
    }, [countryCd])

    const changePM = pmId => {
        if (!pmId) return onPMChange(null);

        const loadPM = pmCatOptions.find(pm => pm.value === pmId)
            || altCatOptions.find(pm => pm.value === pmId);
        if (loadPM) return onPMChange({
            id: pmId,
            categoryCode: pmId,
            methodType: (pmId === 'DC' || pmId === 'CC') ? '' : pmId //set methodType to empty for CC and DC since we will set it by typing in the card number, otherwise it matches the category
        });

        const pm = wallet.find(pm => pm.id === pmId);
        if (pm) return onPMChange(pm);
    };

    const sortedCountriesList = sortCountryList(generateCountryList(countries, countryData, intl));
    return (
    <Details onSubmit={onNext} onCancel={onBack}
        submitLabel="make-payment.btn.continue"
        cancelLabel="ups.btn.back.label"
        canProceed={canProceed}>
        <MDBRow>
            <MDBCol size="12">
                <p className="ups-note-1">*<span className="font-italic">
                    <FormattedMessage id="ups.required-field.note"/>
                </span></p>
            </MDBCol>
        </MDBRow>

        {!isRefund && <MDBRow>
             <MDBCol size="12" md={"6"}>
                <SelectFieldIntl name="payUsing" id="payUsing"
                    value={payUsing}
                    selectOptions={payOptions}
                    label={intl.formatMessage({id: "make-payment.input.payment-type"})}
                    setLabelBefore
                    required
                    onChange={e => {
                        const {value} = e.target;
                        if (value === USE_NEW || value === USE_ALTER) {
                            changePM(newOptions[0].value);
                            setPayLater(false); // TEMP
                            onDateChange(null); // TEMP
                        } else {
                            changePM(wallet[0]?.id || "");
                        }
                        setPayUsing(value);
                    }}
                />
            </MDBCol>
        </MDBRow>}

        <MDBRow>
            <MDBCol size="12" md={"6"}>
                <SelectFieldIntl key={`pm-${payUsing}`} name="paymentMethod" id="paymentMethod"
                    value={paymentMethod?.id || ""}
                    selectOptions={payUsing === USE_NEW
                        ? newOptions
                        : payUsing === USE_ALTER
                            ? altOptions
                            : walletOptions
                    }
                    label={intl.formatMessage({id: isRefund ? "make-payment.refund.to.method" : "modal.payment.payment.method"})}
                    setLabelBefore required
                    onChange={e => changePM(e.target.value)}
                    validations={[
                        (pmId)=> pmTypeValidMinPaymentAmount(merchant, totalAmount)(getPMMethodTypefromId(pmId, wallet)) ? '' : 'paymentAmount.lessThanMin',
                        (pmId)=> pmTypeValidMaxPaymentAmount(merchant, totalAmount)(getPMMethodTypefromId(pmId, wallet)) ? '' : 'paymentAmount.greaterThanMax',
                        ["required", "field.required"]
                    ]}
                />
            </MDBCol>

            {payUsing === USE_NEW && pmCatRequiresAddress(paymentMethod?.id) &&
                <MDBCol size="12" md={"6"}>
                    <SelectFieldIntl name="country" id="country"
                        value={countryCd}
                        selectOptions={sortedCountriesList}
                        label={<FormattedMessage id="ups.enroll.country.label" />}
                        setLabelBefore
                        onChange={({target: {value}}) => {
                            onPMChange({...(paymentMethod ?? {}), address: {...(paymentMethod?.address ?? {}), country: value}});
                        }}
                    />
                </MDBCol>
            }
        </MDBRow>

        {pmForms[paymentMethod?.id]}
        { payUsing === USE_WALLET && isAgreementOptionSelected(paymentMethod) &&
        <MDBRow>
            <AchAgreementConfirmation
                authorizeDD={authorizeDD}
                onAuthorizeACH = {onAuthorizeACH}
                type={paymentMethod?.categoryCode}
            />
        </MDBRow>
        }
        {!isRefund && !hasCredits && (
        <>
            <MDBRow className="mt-5">
                <MDBCol size="12" md={"3"}>
                    <InputFieldIntl type="radio" id={PAY_NOW} name={PAY_NOW}
                        containerClass="mx-0 pl-0"
                        label={intl.formatMessage({id: "modal.payment.pay.now"})}
                        checked={!payLater}
                        onChange={() => {
                            setPayLater(false);
                            onDateChange(null);
                        }}
                    />
                </MDBCol>
            </MDBRow>
             <MDBRow>
                <MDBCol size="6" md={"3"}>
                    <InputFieldIntl type="radio" id={PAY_LATER} name={PAY_LATER}
                        // disabled={payUsing !== USE_WALLET} // TEMP
                        disabled={daysTilDueDate <= 0}
                        containerClass="mx-0 mt-3 pl-0"
                        label={intl.formatMessage({id: "modal.payment.pay.later"})}
                        checked={payLater}
                        onChange={() => setPayLater(true)}
                    />
                </MDBCol>
                <MDBCol size="6" md={"3"}>
                    <DateField id="paymentDate" name="paymentDate" key={`payDate-${payLater}-${paymentMethod?.methodType}`}
                        // disabled={payUsing !== USE_WALLET} // TEMP
                        disabled={daysTilDueDate <= 0}
                        containerClass="md-form mt-0"
                        anchorDirection={"right"}
                        date={date} onDateChange={() => {}}
                        onBlur={d => {
                            if (!d?.value) return;
                            setPayLater(true);
                            onDateChange(d.value);
                        }}
                        focused={focused}
                        onFocusChange={({focused}) => setFocused(focused)}
                        restrict={restrict.datemmddyyyy}
                        validations={payLater ? [
                            R.always(isRealTimeOnlyCard(merchant)(paymentMethod) || (payUsing !== USE_WALLET && isDeferPayWithWalletOnly(merchant)(paymentMethod))
                                ? 'payLater.invalidPaymentMethod'
                                : ''
                            ),
                            ["required", "payDate.required"],
                            ["daterange", `payDate.futureDueDate`, {
                                min: -1,
                                max: -payLaterRange,
                                scale: "days"
                            }]
                        ] : []}
                        range={d => !d.startOf("day").isAfter(moment(), "day")
                            || d.startOf("day").diff(moment(), "days") >= payLaterRange
                        }
                    />
                </MDBCol>
            </MDBRow>
            {(paymentMethod?.categoryCode === "CC") && (businessUnit?.name === "EBS") && (merchant?.countryCode === "US") && (
                <MDBRow>
                    <MDBCol size={"12"}>
                        <Alert
                            type={'warning'}
                            label={'payment-method.credit-surcharge-warning'}
                            values={{link:<a href={"https://www.ups.com/rates"} target={"_blank"}>https://www.ups.com/rates</a>}}
                        />
                    </MDBCol>
                </MDBRow>
            )}
        </>
        )}

        { isDisplaySavePmOption && <>
            <MDBRow>
                <MDBCol size={"12"} className={"text-center mb-4"}>
                    <CheckboxIntl
                        key={"savePaymentMethodToWallet"}
                        name={"savePaymentMethodToWallet"}
                        value={isSavePayMethod}
                        id={"savePaymentMethodToWallet"}
                        containerClass="mx-0 my-0 pl-0"
                        labelClass="mr-0 font-weight-bold"
                        onChange={onSavePM}
                        label={<FormattedMessage id={"check-box-save-payment-method.label"}/>}
                    />
                </MDBCol>
            </MDBRow>
            {(paymentMethod?.methodType === "BACS") && isSavePayMethod && (
                <MDBRow>
                    <MDBCol size={"12"}>
                        <Alert className={"bacs-creation-delay-warning"} type={'warning'} label={'bacs-creation-delay-warning'} />
                    </MDBCol>
                </MDBRow>
            )}
        </>}
    </Details>);
}
